import React from "react";
import styled from "styled-components";

type Props = {
  onPress: () => void;
};

export default function LectureCard({ onPress }: Props) {
  return (
    <Container>
      <Title>[3367] 활동중심 중등 수학교과서 활용 교원 연수(1기)</Title>
      <p>기수 : 종합 22년 8월 / 차수 : 1차 / 수강인원 : n명</p>
      <p>학습기간 : 2022.08.10 11:00 ~ 2022.08.10 18:00</p>
      <Button onClick={onPress}>QR 출석체크 하기</Button>
    </Container>
  );
}

const Container = styled.div`
  height: 186px;
  background: #f6f6f6;
  padding: 16px;
  height: auto;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 19.36px;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border-width: 0px;
  background-color: #448fd9;
  color: #ffffff;
  font-size: 19px;
  line-height: 22.99px;
  font-weight: 700;
  :hover {
    cursor: pointer;
  }
`;
