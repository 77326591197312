import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Router from "./Router";
import { GlobalStyle } from "./styles/GlobalStyle";

function App() {
  const setOneVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setOneVh();
    window.addEventListener("resize", setOneVh);
  }, []);

  return (
    <>
      <GlobalStyle />
      <Router />
    </>
  );
}

export default App;
