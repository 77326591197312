import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
type Props = {
  isShowSplashScreen: boolean;
};
export default function SplashScreen({ isShowSplashScreen }: Props) {
  return (
    <Container isShowSplashScreen={isShowSplashScreen}>
      <Logo src={logo} />
      <div>
        <h1>QR 코드 스캐너</h1>
      </div>
    </Container>
  );
}

const Container = styled.div<{ isShowSplashScreen: boolean }>`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 500px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  opacity: ${(props) => (props.isShowSplashScreen ? "1" : "0")};
  transition: ${(props) =>
    props.isShowSplashScreen
      ? "opacity 500ms"
      : "opacity 500ms, visiblity 500ms"};
  z-index: ${(props) => (props.isShowSplashScreen ? "10" : "-10")};
  left: 0;
`;

const Logo = styled.img`
  width: 50%;
`;
