import { useEffect } from "react";
import styled from "styled-components";

type Props = {
  isShowModal: boolean;
  onSwitchModal: (value: boolean) => void;
  name: string;
};
export default function Modal({ isShowModal, name, onSwitchModal }: Props) {
  useEffect(() => {
    if (isShowModal === true) {
      setTimeout(() => {
        onSwitchModal(false);
      }, 2000);
    }
  }, [isShowModal]);
  return (
    <>
      {isShowModal ? (
        <Container>{name}님을 출석처리 하였습니다.</Container>
      ) : null}
    </>
  );
}

const Container = styled.div`
  display: flex;
  width: 90%;
  height: 100px;
  position: absolute;
  background-color: #e6e6e6;
  bottom: 30px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;
