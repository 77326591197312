import { useState, useEffect } from "react";
import QrCodeView from "./QrCodeView";
import dayjs from "dayjs";

export type QrCodeProps = {
  time: Date;
  onShowTime: (date: Date) => string;
  onShowDate: () => string;
};

export default function QrCode() {
  const date = new Date();
  const [time, setTime] = useState<Date>(date);

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function onShowTime(date: Date) {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const timeStr = hours + ":" + minutes + ":" + seconds;

    return timeStr;
  }

  function onShowDate() {
    const year = dayjs().year();
    const month = dayjs().month() + 1;
    const day = dayjs().date();

    return year + "년 " + month + "월 " + day + "일";
  }

  const props = {
    time: time,
    onShowTime: onShowTime,
    onShowDate: onShowDate,
  };

  return <QrCodeView {...props} />;
}
