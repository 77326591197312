import React, {
  useState,
  ChangeEvent,
  FormEvent,
  useRef,
  Ref,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import LoginView from "./LoginView";

export type PropsType = {
  userId: string;
  userPassword: string;
  onIdChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: ChangeEvent<HTMLInputElement>) => void;
  passwordInputRef: Ref<HTMLInputElement>;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
  isShowSplashScreen: boolean;
};

export default function Login() {
  const [userId, setUserId] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const navigation = useNavigate();
  const [isShowSplashScreen, setIsShowSplashScreen] = useState<boolean>(true);

  const onIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserId(e.target.value);
  };
  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserPassword(e.target.value);
  };
  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (userId === "" && userPassword === "") {
      alert("아이디와 비밀번호를 입력해주세요.");
    }
    if (userId !== "" && userPassword === "") {
      return passwordInputRef.current?.focus();
    }
    if (userId === "admin" && userPassword === "1234") {
      alert("로그인이 완료되었습니다.");
      return navigation("/Lecture");
    }
  };

  const props: PropsType = {
    userId: userId,
    userPassword: userPassword,
    onIdChange: onIdChange,
    onPasswordChange: onPasswordChange,
    passwordInputRef: passwordInputRef,
    onSubmit: onSubmit,
    isShowSplashScreen: isShowSplashScreen,
  };

  return <LoginView {...props} />;
}
