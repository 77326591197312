import React, { useState } from "react";
import styled from "styled-components";
import { PropsType } from "./Login";
import logo from "../assets/logo.png";
import SplashScreen from "../components/SplashScreen";
import Grid from "../components/Grid";

export default function LoginView({
  onIdChange,
  onPasswordChange,
  userId,
  userPassword,
  passwordInputRef,
  onSubmit,
  isShowSplashScreen,
}: PropsType) {
  return (
    <>
      <Grid background={false}>
        <Logo src={logo} />
        <LoginFormContainer onSubmit={onSubmit}>
          <InputContainer>
            <InputLabel htmlFor="id">아이디</InputLabel>
            <Input
              type={"text"}
              name="id"
              value={userId}
              onChange={onIdChange}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel htmlFor="password">비밀번호</InputLabel>
            <Input
              ref={passwordInputRef}
              type={"password"}
              name="password"
              value={userPassword}
              onChange={onPasswordChange}
            />
          </InputContainer>
          <LoginButton type={"submit"} value="로그인" />
        </LoginFormContainer>
      </Grid>
    </>
  );
}

const LoginFormContainer = styled.form`
  flex-direction: column;
  width: 100%;
`;

const InputLabel = styled.label`
  width: 100px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 24px;
`;

const LoginButton = styled.input`
  width: 100%;
  height: 36px;
  border-radius: 5px;
`;
const Logo = styled.img`
  width: 50%;
  margin-bottom: 50px;
`;
