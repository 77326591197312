import styled from "styled-components";
import React from "react";

type Props = {
  children: React.ReactNode;
  background: boolean;
};

export default function Grid({ children, background }: Props) {
  return <Container background={background}>{children}</Container>;
}

const Container = styled.div<{ background: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 16px;
  background: ${(props) => (props.background ? "#ffffff" : "#ffffff")};
`;
