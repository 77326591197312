import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "../assets/logo.png";

const repeatBanner = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);

    }
`;

export default function LogoBanner() {
  return (
    <Container>
      <Span>
        <Section>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
          <span>
            <Image src={logo} />
          </span>
        </Section>
      </Span>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 55px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 50px;
  align-items: center;
  flex-direction: row-reverse;
  overflow: hidden;
`;

const Span = styled.span`
  display: inline-block;
  animation: 200s linear 0s infinite normal none running ${repeatBanner};
`;

const Section = styled.section`
  white-space: nowrap;
`;

const Image = styled.img`
  width: 150px;
  margin-right: 20px;
`;
