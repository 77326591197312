import styled from "styled-components";
import Grid from "../components/Grid";
import logo from "../assets/logo.png";
import { QrCodeProps } from "./QrCode";
import LogoBanner from "../components/LogoBanner";
import { QRCodeSVG } from "qrcode.react";

export default function QrCodeView({
  onShowDate,
  onShowTime,
  time,
}: QrCodeProps) {
  const qrData = JSON.stringify({
    name: "홍길동",
  });
  return (
    <Grid background={true}>
      <Logo src={logo} />
      <TimeContainer>
        {onShowDate()}
        <br />
        {onShowTime(time)}
      </TimeContainer>
      <QRCodeSVG size={240} value={qrData} />
      <LogoBanner />
    </Grid>
  );
}

const Logo = styled.img`
  width: 65%;
  margin-bottom: 50px;
`;

const TimeContainer = styled.p`
  text-align: center;
  font-size: 19px;
  color: #000000;
  font-weight: 700;
  line-height: 22.99px;
`;
