import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { QrReader } from "react-qr-reader";
import Grid from "./Grid";
import { QrCodeViewFinder } from "./QrCodeViewFinder";
import Modal from "./Modal";

export default function QrCodeScan() {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [data, setData] = useState<string>("");
  const onResult = (result: any, error: any) => {
    if (!!result) {
      const data = JSON.parse(result?.text);
      if (window.confirm(`${data.name}님을 출석처리 하시겠습니까?`)) {
        setIsShowModal(true);
        setData(data.name);
      }
    }
  };

  const onSwitchModal = () => {
    setIsShowModal(!isShowModal);
  };

  return (
    <Grid background={false}>
      <QrCodeContainer>
        <QrReader
          ViewFinder={() => <QrCodeViewFinder />}
          scanDelay={1000}
          constraints={{ facingMode: "environment" }}
          videoStyle={{ width: "100%", height: "100%" }}
          onResult={onResult}
        />
        {/* <Button onClick={onSwitchModal}>모달</Button> */}
      </QrCodeContainer>
      <Modal
        isShowModal={isShowModal}
        onSwitchModal={onSwitchModal}
        name={data}
      />
    </Grid>
  );
}

const QrCodeContainer = styled.section`
  display: inline-block;
  width: 100%;
  padding: 0 -10px 0 -10px;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button``;
