import React from "react";
import { useNavigate } from "react-router-dom";
import LectureView from "./LectureView";

export type LectureProps = {
  onNavigateToQrCodeScan: () => void;
};

export default function Lecture() {
  const navigate = useNavigate();
  const onNavigateToQrCodeScan = () => {
    navigate("/qrcodescanner");
  };

  const props = {
    onNavigateToQrCodeScan: onNavigateToQrCodeScan,
  };
  return <LectureView {...props} />;
}
