import Grid from "../components/Grid";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SplashScreen from "../components/SplashScreen";
import { useEffect } from "react";

export default function Main() {
  const navigate = useNavigate();
  const [isShowSplashScreen, setIsShowSplashScreen] = useState<boolean>(true);

  const onNavigateToQrCode = () => {
    navigate("qrcode");
  };
  const onNavigateToLogin = () => {
    navigate("login");
  };
  useEffect(() => {
    setTimeout(() => {
      setIsShowSplashScreen(false);
    }, 1500);
  }, []);
  return (
    <>
      <SplashScreen isShowSplashScreen={isShowSplashScreen} />
      <Grid background={true}>
        <Button onClick={onNavigateToQrCode}>수강생 QR코드 생성</Button>
        <Button onClick={onNavigateToLogin}>관리자 페이지</Button>
      </Grid>
    </>
  );
}

const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border-width: 0px;
  background-color: #448fd9;
  color: #ffffff;
  font-size: 19px;
  line-height: 22.99px;
  font-weight: 700;
  :hover {
    cursor: pointer;
  }
  margin-bottom: 100px;
`;
