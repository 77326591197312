import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        max-width: 500px;
        min-width: 280px;
        height: calc(var(--vh, 1vh) * 100);
        margin : 0 auto;
        box-sizing: border-box;
        /* padding : 0 16px; */
    }

    video {
        width : 100% !important;
        height: auto !important;
    }
`;
