import React from "react";
import Grid from "../components/Grid";
import LectureCard from "../components/LectureCard";
import { LectureProps } from "./Lecture";

export default function LectureView({ onNavigateToQrCodeScan }: LectureProps) {
  return (
    <Grid background={false}>
      <LectureCard onPress={onNavigateToQrCodeScan} />
    </Grid>
  );
}
