import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Lecture from "./pages/Lecture";
import Login from "./pages/Login";
import QrCode from "./pages/QrCode";
import QrCodeScanner from "./pages/QrCodeScanner";
import Main from "./pages/Main";
export default function Router() {
  return (
    <Routers>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/lecture" element={<Lecture />} />
        <Route path="/qrcode" element={<QrCode />} />
        <Route path="/qrcodescanner" element={<QrCodeScanner />} />
      </Routes>
    </Routers>
  );
}
